@import (css)
  url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);

@import (less) "variables.less";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: @background-color;
}

body {
  padding: 0;
  padding-bottom: 4em;
  margin: 0;

  background-color: @background-color;
  color: #ccc;

  font-family: "Open Sans", sans-serif;
  font-size: 11pt;

  @media screen and (max-width: @small-screen) {
    font-size: 10pt;
  }

  a {
    text-decoration: none;
    color: @link-color;
    transition: all 0.3s ease;

    &:hover {
      color: @link-hover-color;
    }
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1em;

  @media screen and (max-width: @small-screen) {
    padding: 1em 0.5em;
  }

  @media screen and (max-width: @x-small-screen) {
    padding: 1em 0.1em;
  }
}

.prose {
  max-width: @small-screen;
  line-height: 150%;
}

.link-list {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 1em;

  li {
    padding: 1em;
    list-style: none;
    font-size: large;

    span {
      margin-left: 0.5em;
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  border-right: 1px solid @table-border-color;

  font-size: 1em;

  a {
    color: inherit;
    transition: all 0.3s ease;

    &:hover {
      color: @link-hover-color;
    }
  }

  th,
  td {
    padding: 0.5em;
    text-align: left;
    border-bottom: 1px solid @table-border-color;

    &.drop-if-small {
      @media screen and (max-width: @x-small-screen) {
        display: none;
      }
    }
  }

  th {
    background-color: @table-header-background-color;
    border-top: 1px solid @table-border-color;
  }

  tr {
    background-color: @table-row-background-color;
  }

  tr:nth-child(even) {
    background-color: @table-row-alternate-background-color;
  }

  th:first-child,
  td:first-child {
    border-left: 1px solid @table-border-color;
  }

  th:last-child,
  td:last-child {
    border-right: 1px solid @table-border-color;
  }
}

.table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  padding: 2em 1em;

  @media screen and (max-width: @small-screen) {
    padding: 2em 0.1em;
  }
}

.code {
  font-family: monospace;
}

.alert-container {
  position: fixed;
  top: 1em;
  right: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1000;
}
