* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  background-color: #05050a;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  color: #ccc;
  background-color: #05050a;
  margin: 0;
  padding: 0 0 4em;
  font-family: Open Sans, sans-serif;
  font-size: 11pt;
}

@media screen and (width <= 768px) {
  body {
    font-size: 10pt;
  }
}

body a {
  color: #eef;
  text-decoration: none;
  transition: all .3s;
}

body a:hover {
  color: #fa3;
}

.page-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
}

@media screen and (width <= 768px) {
  .page-content {
    padding: 1em .5em;
  }
}

@media screen and (width <= 576px) {
  .page-content {
    padding: 1em .1em;
  }
}

.prose {
  max-width: 768px;
  line-height: 150%;
}

.link-list {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 1em;
  display: flex;
}

.link-list li {
  padding: 1em;
  font-size: large;
  list-style: none;
}

.link-list li span {
  margin-left: .5em;
}

table {
  border-collapse: collapse;
  border-right: 1px solid #333;
  width: 100%;
  max-width: 100%;
  font-size: 1em;
  overflow-x: auto;
}

table a {
  color: inherit;
  transition: all .3s;
}

table a:hover {
  color: #fa3;
}

table th, table td {
  text-align: left;
  border-bottom: 1px solid #333;
  padding: .5em;
}

@media screen and (width <= 576px) {
  table th.drop-if-small, table td.drop-if-small {
    display: none;
  }
}

table th {
  background-color: #2a2f3a;
  border-top: 1px solid #333;
}

table tr {
  background-color: #050507;
}

table tr:nth-child(2n) {
  background-color: #0a0c0f;
}

table th:first-child, table td:first-child {
  border-left: 1px solid #333;
}

table th:last-child, table td:last-child {
  border-right: 1px solid #333;
}

.table-container {
  width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  overflow-x: auto;
}

@media screen and (width <= 768px) {
  .table-container {
    padding: 2em .1em;
  }
}

.code {
  font-family: monospace;
}

.alert-container {
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 1em;
  right: 1em;
}
/*# sourceMappingURL=index.c09bf271.css.map */
